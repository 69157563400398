import axios from "axios";
import { dispatchtoast, showError } from "../../Utils";

// export const APIURL = "http://192.168.1.37:9010/";
export const APIURL="https://api.ezulixdesk.com/"
export const SOCKET_URL = "wss://api.ezulixdesk.com/";

// export const APIURL="https://uat-api.helpdesk.ezulix.co/"
// export const SOCKET_URL = "wss://uat-api.helpdesk.ezulix.co/";

const ApiService = axios.create({
  withCredentials: true,
  baseURL: APIURL,
});

// Add a request interceptor
ApiService.interceptors.request.use((config) => {
  return config;
});
// Add a response interceptor
ApiService.interceptors.response.use(
  (response) => {
    if (response.data.status||response.data.success) {
      return response.data;
    } else if (response.data.formErrors) {
      showError(response.data.formErrors);
    } else if (response.data.validationError) {
      showError(response.data.validationError);
    } else {
      dispatchtoast(response.data.message ?? response.data.error, true);
    }
    return response.data;
  },
  (error) => {
    // Handle errors if needed
    const { status } = error?.response;
    if (status === 401) {
      dispatchtoast(
        error?.response.data.message ?? error?.response.data.msg,
        true
      );
      localStorage.clear()
      window.location.href = "/";
    }
    if (status && status >= 400 && status < 500) {
      if (error.response.data.formErrors) {
        showError(error.response.data.formErrors);
      } else if (error.response.data.validationError) {
        showError(error.response.data.validationError);
      } else {
        dispatchtoast(
          error?.response.data.message ?? error?.response.data.error,
          true
        );
      }
    } else if (status && status >= 500) {
      dispatchtoast(
        error?.response.data.message ?? error?.response.data.msg,
        true
      );
    }
    return Promise.reject(error);
  }
);

export default ApiService;
