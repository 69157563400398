import React, { useEffect, useState } from "react";
import { Dropdown, Layout, Menu } from "antd";
import { MenuItems } from "./MenuItems";
import { RiMenuFold2Fill, RiMenuFoldFill } from "react-icons/ri";
import { IoMdArrowDropdown } from "react-icons/io";
import Searchbar from "./Searchbar";
import { NavLink, useNavigate } from "react-router-dom";
import NewConatct from "../CommonComponents/NewContact";
import { getLocalStorageItem, trimString } from "../Utils";
import {
  getPictureUploadUser,
  getPictureUploadVendor,
} from "../Apiservices/CommonFun";
import { useUserDetailStore } from "../Store/UserDetailReducer";
import { useCommmonStore } from "../Store/CommonReducer";
import { Images } from "../ImageController";
import {
  ProfileDetail,
  ProfileDetailUser,
} from "../Views/User/Profile/ProfileFun";

const { Header, Sider, Content } = Layout;
const ProjectLayout = ({ children }) => {
  const { selected } = useCommmonStore();
  const navigate = useNavigate();
  const Role = getLocalStorageItem("Role");
  // const [profileData, setProfileData] = useState(null);
  const [collapsed, setCollapsed] = useState(true);
  const [openContactDrawer, setOpenContactDrawer] = useState(false);
  // const [windowwidth, setwindowwidth] = useState(window.innerWidth);
  const { vendorProfile,setProfileDetail, userProfile,profileDetail, getUserProfile, getVendorProfile } =
    useUserDetailStore();
  // useEffect(() => {
  //   const handleResize = () => {
  //     setwindowwidth(window.innerWidth);
  //   };
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  // useEffect(() => {
  //   if (windowwidth > 1000) {
  //     setCollapsed(true);
  //   } else {
  //     setCollapsed(true);
  //   }
  // }, [windowwidth]);
  const getProfilePictures = async () => {
    try {
      let res;
      res =
        Role === "vendor"
          ? await getPictureUploadVendor()
          : await getPictureUploadUser();
      if (res.status) {
        if (Role === "vendor") {
          getVendorProfile(res.imagePath);
        } else {
          getUserProfile(res.imagePath);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getProfileDetail = async () => {
    try {
      let res;
      res = Role === "user" ? await ProfileDetailUser() :await ProfileDetail();
      if (res.status && Role==="user") {
        setProfileDetail(res.data);
      }else if(res.status && Role==="vendor"){
        setProfileDetail(res.result[0])
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProfilePictures();
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    getProfileDetail();
    //eslint-disable-next-line
  }, []);
  const items = [
    {
      key: "1",
      label: (
        <NavLink to={"/new-email"}>
          <p>New Email</p>
        </NavLink>
      ),
    },
    {
      key: "2",
      label: <p onClick={() => setOpenContactDrawer(true)}>New Contact</p>,
    },
  ];
  const Profileitems = [
    {
      key: "2",
      label: (
        <p
          onClick={() => {
            navigate("/profile");
          }}
        >
          View Profile
        </p>
      ),
    },
    {
      key: "1",
      label: (
        <p
          onClick={() => {
            localStorage.clear();
            navigate("/");
          }}
        >
          Logout
        </p>
      ),
    },
  ];

  return (
    <>
      <Layout hasSider className="min-h-screen sidebar">
        <Sider
          style={{
            position: "fixed",
            marginBottom: "20px",
            overflowY: "scroll",
            height: "100vh",
          }}
          trigger={null}
          collapsible
          className=""
          collapsed={collapsed}
        >
          <div className="demo-logo-vertical flex justify-around items-center h-20">
            {!collapsed ? (
              <>
                <div className="flex gap-4 bg-primary w-full h-full justify-center items-center">
                  {/* <p className="font-poppins text-xl text-white">HelpDesk</p>{" "} */}
                   <img
                   loading="lazy"
                    src={Images.websiteLogo}
                    className=" w-[85%] "
                    alt="Weblogo"
                  /> 
                </div>
                {/* <img
                  onClick={() => setCollapsed(!collapsed)}
                  className="cursor-pointer"
                  src={Images.Hamburger}
                  alt="logo"
                  width={30}
                  height={30}
                /> */}
              </>
            ) : (
              <>
                <div className="flex gap-4 cursor-pointer bg-primary w-full h-full justify-center items-center">
                <img
                   loading="lazy"
                    src={Images.smallLogo}
                    className=" w-10 h-10 object-contain "
                    alt="Weblogo"
                  /> 
                </div>
              </>
              //   <img src={Images.Logo} alt="WeblogoSmall" className="h-8 w-8" />
            )}
          </div>
          <Menu
            theme="dark"
            mode="inline"
            className="mt-4"
            defaultSelectedKeys={["1"]}
            selectedKeys={[selected]}
            items={MenuItems()}
          />
        </Sider>
        <Layout className="w-full">
          <Header
            style={{
              marginLeft: collapsed ? 45 : 200,
            }}
            className=" bg-primary  flex justify-between items-center h-20"
          >
            <div className="hidden md:flex w-full flex-wrap justify-start gap-x-3 mx-4  items-center">
              {collapsed ? (
                <p>
                  {" "}
                  <RiMenuFold2Fill
                    size={20}
                    onClick={() => setCollapsed(!collapsed)}
                    className="text-white cursor-pointer"
                  />
                </p>
              ) : (
                <p>
                  {" "}
                  <RiMenuFoldFill
                    size={20}
                    onClick={() => setCollapsed(!collapsed)}
                    className="text-white cursor-pointer"
                  />
                </p>
              )}
            </div>
            <div className="pr-5 w-full flex justify-end items-center gap-x-4">
              <div className="hidden w-full md:flex">
                <Searchbar />
              </div>
              <Dropdown menu={{ items }} trigger={["click"]}>
                <button className="bg-[#2BC155] hidden w-40 xl:w-28   cursor-pointer rounded-xl font-poppins font-semibold  text-white lg:flex justify-center items-center h-12">
                 + New
                </button>
              </Dropdown>
              <Dropdown trigger={["click"]} menu={{ items: Profileitems }}>
                <div className="bg-[#A793FF] hidden cursor-pointer font-inter lg:flex justify-between gap-x-2 items-center w-auto p-1 rounded-xl">
                  <div className="w-10 h-10 object-contain rounded-xl bg-white">
                    <img
                      src={Role === "vendor" ? vendorProfile : userProfile}
                      onError={(e) => {
                        e.target.src = Images.dummyuser;
                        e.target.alt = "dummy";
                      }}
                      loading="lazy"
                      alt="userPhoto"
                      className="w-10 h-10  object-contain bg-secondary m-auto rounded-xl"
                    />
                  </div>
                  <p className="text-sm w-max text-white">
                    {Role==="user"?trimString(profileDetail?.user_name, 20):Role==="vendor"?trimString(profileDetail?.name, 20):""}
                    
                  </p>
                  <IoMdArrowDropdown color="white" size={18} />
                </div>
              </Dropdown>
              <Dropdown trigger={["click"]} menu={{ items: Profileitems }}>
                <div className="w-10 h-10 rounded-xl bg-white  flex lg:hidden justify-end items-center ">
                  <img
                    loading="lazy"
                    src={
                      (Role === "vendor" ? vendorProfile : userProfile) ??
                      Images.dummyuser
                    }
                    onError={(e) => {
                      e.target.src = Images.dummyuser;
                      e.target.alt = "dummy";
                    }}
                    alt="userPhoto"
                    className="w-10 h-10 object-contain bg-secondary  rounded-xl"
                  />
                </div>
              </Dropdown>
            </div>
          </Header>
          <Content
            className={`bg-[#F7F8FA] h-full p-5 md:p-8
                 ${collapsed ? "ml-[45px]" : "ml-[200px]"}
            `}
          >
            {/* <div className="mb-5 w-full flex justify-between items-center gap-4">
              <BsArrowLeft
                size={20}
                onClick={() => navigate(-1)}
                className="cursor-pointer"
              />
            </div> */}

            {children}
          </Content>
        </Layout>
      </Layout>
      {openContactDrawer && (
        <NewConatct
          openDrawer={openContactDrawer}
          setOpen={setOpenContactDrawer}
          type={"add"}
        />
      )}
    </>
  );
};

export default ProjectLayout;
