import { Button, Form } from "antd";
import React, { useState } from "react";
import { createNewLink } from "./AuthFun";
import { dispatchtoast } from "../../Utils";
import { useLocation } from "react-router-dom";
import { Images } from "../../ImageController";

const GenerateLink = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const location = useLocation();
  const param = new URLSearchParams(location.search);
  const getVendorId = param.get("vendorId");
  const handleGenerateLink = () => {
    setBtnLoading(true);
    createNewLink(
      `${location.pathname.split("/")[2].split("=")[1]}&vendorId=${getVendorId}`
    )
      .then((res) => {
        if (res.status) dispatchtoast(res.message);
      })
      .catch((error) => console.log(error))
      .finally(() => setBtnLoading(false));
  };
  return (
    <div className="flex justify-center bg-[#F7F6FF] font-poppins items-center h-screen">
      <div
        style={{ boxShadow: " 0px 0px 35px 0px #9AA1AB26" }}
        className="bg-white rounded-md m-4 w-[500px] px-8 py-8  "
      >
        <div className="text-secondary flex justify-center items-center w-1/2  m-auto   px-4 py-2 rounded-lg">
            <img loading="lazy" src={Images.weblogocolor} className="flex justify-center items-center" alt="weblogo" />
          </div>

        <p className="mt-4 text-center font-semibold text-[#3D4465]">
          Generate Reset Password Link
        </p>
        <Form onFinish={(val) => handleGenerateLink(val)}>
          <Form.Item>
            <Button
              loading={btnLoading}
              type="primary"
              htmlType="submit"
              style={{ color: "white" }}
              className="font-poppins w-full mt-4  text-white font-bold rounded-lg bg-secondary"
            >
              Click here for new link
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default GenerateLink;
